import React from 'react';
import {
    List,
    Edit,
    Datagrid,
    TextField,
    SelectInput,
    SelectField,
    Create,
    SimpleForm,
    TextInput,
    EditButton,
    TopToolbar,
    sanitizeListRestProps,
    CreateButton,
    Filter,
    SearchInput,
    FunctionField,
    Pagination,
    Show,
    SimpleShowLayout,
    ShowButton,
    Button,
    EmailField
} from 'react-admin';
import CustomExportButton from '../../components/CustomExportButton';
import ImpersonateButton from '../../components/ImpersonateButton';

const Action = ({ total, className, basePath, ...rest }) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
      <CustomExportButton jobName={'userExportReport'} disabled={total === 0} />
    </TopToolbar>
  );
};

const UserFilters = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const UserPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250, 500]} {...props} />;

const ModelList = props => (
  <List {...props} filters={<UserFilters />} actions={<Action />} perPage={100} pagination={<UserPagination/>}>
    <Datagrid>
      <TextField source='id' />
      <FunctionField label="Name" sortBy="lastName" render={
          record => `${record.firstName} ${record.lastName}`
      } />
      <EmailField source='email' />
      <SelectField
        source='role'
        choices={[
          { id: 'superadmin', name: 'SuperAdmin' },
          { id: 'rentsafe_admin', name: 'Admin' },
          { id: 'property_manager', name: 'Property Manager' },
          { id: 'user', name: 'User' },
        ]}
      />
      <EditButton label="Edit"/>
      <ShowButton label="View"/>
      <ImpersonateButton />
    </Datagrid>
  </List>
);

const ModelCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={`email`} options={{ label: 'Email' }} />
      <TextInput source={`firstName`} options={{ label: 'First Name' }} />
      <TextInput source={`lastName`} options={{ label: 'Last Name' }} />
      <TextInput source={`phone`} options={{ label: 'Phone' }} />
      <TextInput source={`password`} options={{ label: 'Password' }} />
      <SelectInput
        source={`role`}
        choices={[
          { id: 'superadmin', name: 'SuperAdmin' },
          { id: 'rentsafe_admin', name: 'Admin' },
          { id: 'property_manager', name: 'Property Manager' },
          { id: 'user', name: 'User' },
        ]}
        options={{ label: 'Role' }}
      />
    </SimpleForm>
  </Create>
);

const ModelEdit = props => (
  <Edit undoable={true} {...props}>
    <SimpleForm>
      <TextInput source={`email`} options={{ label: 'Email' }} />
      <TextInput source={`firstName`} options={{ label: 'First Name' }} />
      <TextInput source={`lastName`} options={{ label: 'Last Name' }} />
      <SelectInput
        source={`role`}
        choices={[
          { id: 'superadmin', name: 'SuperAdmin' },
          { id: 'rentsafe_admin', name: 'Admin' },
          { id: 'property_manager', name: 'Property Manager' },
          { id: 'user', name: 'User' },
        ]}
        options={{ label: 'Role' }}
        disabled={true}
      />
    </SimpleForm>
  </Edit>
);

const ShowAction = ({ basePath, data }) => {
    return (
        <TopToolbar>
            <Button label='Back to List' onClick={() => { window.location.href = '/#/AdminUser'; }} />
            <EditButton basePath={basePath} record={data} />
        </TopToolbar>
    );
};

const ModelShow = (props) => (
    <Show {...props} actions={<ShowAction/>} >
        <SimpleShowLayout>
            <TextField
                source={'firstName'}
                label={'First Name'}
            />
            <TextField
                source={'lastName'}
                label={'Last Name'}
            />
            <TextField source='email' />
            <SelectField
                source='role'
                choices={[
                    { id: 'superadmin', name: 'SuperAdmin' },
                    { id: 'rentsafe_admin', name: 'Admin' },
                    { id: 'property_manager', name: 'Property Manager' },
                    { id: 'user', name: 'User' },
                ]}
            />
        </SimpleShowLayout>
    </Show>
);

export default {
  name: 'AdminUser',
  options: {
    label: 'User',
  },
  show: ModelShow,
  list: ModelList,
  create: ModelCreate,
  edit: ModelEdit,
};
